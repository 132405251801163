<template>
  <v-container fluid>
    <TableViewComponent
      title="Table Spécialités médicales"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter une spécialité médicale"
      itemLabel="spécialités médicales"
      :items="items"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette spécialité médicale?"
      :pagination="false"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import { FonctionService } from "@/service/sfr/function_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableMedicalSpecialties",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les éléments */
      items: [],

      /**le service d'accès à l'api */
      serviceMedicalSpecialties: null,

      serviceFunction: null,
    };
  },
  methods: {
    onAddElement() {
      this.$router.push(routes.medicalSpecialties.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.items = [];

        let items = await this.serviceMedicalSpecialties.getAll();

        //chargement des fonctions pour jointure
        let functions = await this.serviceFunction.getAll();

        items.forEach((r) => {
          let item = JSON.parse(JSON.stringify(r));

          //le nom de la fonction
          item.function = "-";
          if (item.functionId) {
            let func = functions.find((f) => f.id === item.functionId);
            if (func) {
              item.function = func.name;
            } else {
              console.error("item " + JSON.stringify(item, null, 4));
            }
          }

          //Formulaire externe report chirurgies
          if (item.externalSurgicalDeferralForm) {
            item.form = "oui";
          } else {
            item.form = "non";
          }

          //action de consultation
          item.view = () => {
            this.$router.push(
              routes.medicalSpecialties.view.root + "/" + item.id
            );
          };

          //action de modification
          item.edit = () => {
            this.$router.push(
              routes.medicalSpecialties.edit.root + "/" + item.id
            );
          };

          //action de suppression
          item.delete = async () => {
            try {
              await this.serviceMedicalSpecialties.delete(item.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "problème lors de la suppression : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.items.push(item);

          // console.log(JSON.stringify(this.items, null, 4));
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "problème lors du chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
          default: true,
        },
        {
          text: "Nomenclature",
          align: "start",
          sortable: true,
          value: "nomenclature",
          default: true,
        },
        {
          text: "Nom digital du spécialiste",
          align: "start",
          sortable: true,
          value: "function",
          default: true,
        },
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "name",
          default: false,
        },
        {
          text: "Dénomination commerciale",
          align: "start",
          sortable: true,
          value: "commercialName",
          default: false,
        },
        {
          text: "Formulaire externe report chirurgies",
          align: "start",
          sortable: true,
          value: "form",
          default: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditPracticioner];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditPracticioner, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadPracticioner];
    },
  },
  mounted() {
    this.serviceMedicalSpecialties = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi()
    );

    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());

    this.load();
  },
};
</script>

<style>
</style>